/**
 * Created by morteza on 5/4/17.
 */
import PersianDate from "jalaali-js";
import classnames from "classnames";
import errorsStrings from "./errorsStrings";
import languages from "languages";
export const UserInit = {
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  mobile: null,
  phone: null,
  image: null,
  token: null,
  plan_id: 2,
  email_status: "Inactive",
  mobile_status: "Inactive",
  status: "Inactive",
  otp: "Inactive",
  profile_picture: null,
  identity_attachment: null,
  sms_notification: "Inactive",
  email_notification: "Inactive",
  ref_currency_id: null,
  timezone_id: null,
  renew: "Inactive",
  created_at: "2000-01-01 12:00:00",
  updated_at: "2000-01-01 12:00:01",
  username: "",
  strength: 0,
  promotion_code: "",
  national_code: "",
};

export const SITE_URL = process.env.REACT_APP_API_URL;

export const getClass = (stateName) => {
  const err = this.state[stateName + "Err"];
  let classErr = "has-danger";
  if (err === "ss") classErr = "";
  else if (err === "") classErr = "";
  return classnames(classErr, {
    "input-group-focus mx-auto": this.state.focus === stateName,
  });
};
export const getError = (bootData, code, lang = "en") => {
  const error =
    bootData.errors && bootData.errors.find((item) => item.code === code);
  if (error) return error[lang];
  else {
    console.log("Error", "Code: " + code);
    return false;
  }
};

export const makeAmountLimitation = (state) => {
  if (state.selCurrency.value.id === 2) {
    return "(5.00 - 2500.00)";
  } else if (state.selCurrency.value.id === 18) {
    return "(10.00 - 15000.00)";
  } else {
    return "";
  }
};

export const nl2br = (str, is_xhtml) => {
  var breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
};

export const getAllCurrencies = (props) => {
  const currencies = props.userData?.currencies?.map((item) => {
    return {
      label: item.code,
      value: item,
    };
  });
  const filteredCurrencies = currencies?.filter((item) => item.label !== "IRR");
  return filteredCurrencies;
};

export const getWallets = (props) => {
  const wallets = props.userData?.currencies?.map((item) => {
    return {
      label: item.code,
      value: item,
    };
  });
  return wallets;
};
// export const getError = (bootData, code) => {
//     const error = bootData.errors.find(item => item.code === code);
//     if(error)
//         return I18nManager.isRTL? error.fa: error.en;
//     else
//         return (I18nManager.isRTL? "خطای شماره: ": "Error code: ") +code;
// };
export const numberWithCommas = (x) => {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
export const ValidationErrors = {
  formRequired: "نمی تواند خالی باشد",
  formShort: "خیلی کوتاه است",
  formNotOk: "معتبر نیست",
  formNotEqualPass: "کلمه عبور و تکرار آن یکسان نیست",
  formImportant: "ضروری است",
  formPassWeak: "رمز عبور ضعیف است.",
  errorServer: "خطای سرور رخ داد",
  errorApp: "خطای برنامه رخ داد",
  errorDisconnected: "اتصال اینترنت قطع است",
  errorConnection: "نمی تواند به سرور متصل شود",
  errorNotAuth: "کاربر اعتبارسنجی نشد",
};

// export const ValidationErrors= {
//     formRequired: I18nManager.isRTL? 'نمی تواند خالی باشد': 'This field is Required',
//     formShort: I18nManager.isRTL? 'خیلی کوتاه است': 'It\'s short',
//     formNotOk: I18nManager.isRTL? 'معتبر نیست': 'It\'s not a valid value',
//     formNotEqualPass: I18nManager.isRTL? 'رمز عبور و تائید آن یکسان نیست': 'Password and confirm password is not equal',
//     formImportant: I18nManager.isRTL? 'ضروری است': 'This field is Important',
//     formPassWeak: I18nManager.isRTL? 'رمز عبور ضعیف است.': 'It\'s weak password',
//     errorServer: I18nManager.isRTL? 'خطای سرور رخ داد': 'Server error',
//     errorApp: I18nManager.isRTL? 'خطای برنامه رخ داد': 'Application error',
//     errorDisconnected: I18nManager.isRTL? 'اتصال اینترنت قطع است': 'Network disconnected',
//     errorConnection: I18nManager.isRTL? 'نمی تواند به سرور متصل شود': 'Can\'t connect to server',
//     errorNotAuth: I18nManager.isRTL? 'کاربر اعتبارسنجی نشد': 'User not authenticated',
// };

export const userIsLoggedIn = (user) => user.token !== null;

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePhone = (phone) => {
  const re = /^0[^09][0-9]{9}$/;
  return re.test(phone);
};

export const validateMobile = (mobile) => {
  const re = /^09[0-9]{9}$/;
  return re.test(mobile);
};

export const validateNumber = (value) => {
  const re = /^[0-9]+$/;
  return re.test(value);
};

export const validateIBAN = (value) => {
  const re = /^[a-zA-Z]{2}\d+$/;
  return re.test(value);
};

export const validateUrl = (value) => {
  const re =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return re.test(value);
};

export const validateIp = (value) => {
  const re =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return re.test(value);
};

export const verifyLength = (value, length) => value.length >= length;

/**
 * @return {string}
 */
export function ConvertNumToEn(string) {
  return string
    .toString()
    .replace(/[\u0660-\u0669]/g, function (c) {
      return c.charCodeAt(0) - 0x0660;
    })
    .replace(/[\u06f0-\u06f9]/g, function (c) {
      return c.charCodeAt(0) - 0x06f0;
    });
}

export const getDateByLocale = (dateMiladi, lang = "en") => {
  if (lang === "fa") {
    const dateTimeArr = dateMiladi.split(" ");
    const dateArr = dateTimeArr[0].split("-");
    const date = PersianDate.toJalaali(
      parseInt(dateArr[0]),
      parseInt(dateArr[1]) - 1,
      parseInt(dateArr[2])
    );
    return `${dateTimeArr[1]} ${date.jy}/${getNumber(date.jm)}/${getNumber(
      date.jd
    )}`;
  } else return dateMiladi.replace(/-/g, "/");
};

export const getNumber = (number) => (number < 10 ? `0${number}` : `${number}`);

export function checkMelliCode(codeMelli) {
  if (codeMelli.length !== 10) return false;

  if (
    codeMelli === "0000000000" ||
    codeMelli === "1111111111" ||
    codeMelli === "2222222222" ||
    codeMelli === "3333333333" ||
    codeMelli === "4444444444" ||
    codeMelli === "5555555555" ||
    codeMelli === "6666666666" ||
    codeMelli === "7777777777" ||
    codeMelli === "8888888888" ||
    codeMelli === "9999999999"
  )
    return false;

  let c = parseInt(codeMelli.substring(9, 10));

  let n =
    parseInt(codeMelli.substring(0, 1)) * 10 +
    parseInt(codeMelli.substring(1, 2)) * 9 +
    parseInt(codeMelli.substring(2, 3)) * 8 +
    parseInt(codeMelli.substring(3, 4)) * 7 +
    parseInt(codeMelli.substring(4, 5)) * 6 +
    parseInt(codeMelli.substring(5, 6)) * 5 +
    parseInt(codeMelli.substring(6, 7)) * 4 +
    parseInt(codeMelli.substring(7, 8)) * 3 +
    parseInt(codeMelli.substring(8, 9)) * 2;

  let r = n - Math.floor(n / 11) * 11;

  return (
    (r === 0 && r === c) || (r === 1 && c === 1) || (r > 1 && c === 11 - r)
  );
}

export class GetUrl {
  static getItemPicUrl(picture) {
    return SITE_URL + "upload/item_pic/" + picture;
  }

  static getGroupPicUrl(item) {
    return { uri: `${SITE_URL}upload/groupItem/${item.id}.${item.picExt}` };
  }

  static getSlidePicUrl(item) {
    return { uri: `${SITE_URL}upload/slide_pic/${item.id}.${item.picExt}` };
  }
}

export const getLang = (props) => {
  const lang = props.match.params.lang;
  return lang && ["en", "fa"].includes(lang) ? lang : "fa";
};

export const setLink = (path, lang) => `/${lang ? lang : "en"}${path}`;

export const formatMoneyField = (number) =>
  number
    .toString()
    .replace(/,/g, "")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const formatMoney = (number) =>
  parseFloat(number)
    .toFixed(1)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const showToast = (text, duration) => {
  // Toast.show(text, {duration, textStyle: {fontFamily: Font.normal}});
};

export const Flags = {
  EUR: require("./assets/img/flags/european-union.png"),
  IRR: require("./assets/img/flags/iran.png"),
  CHF: require("./assets/img/flags/switzerland.png"),
  AED: require("./assets/img/flags/united-arab-emirates.png"),
  CNY: require("./assets/img/flags/china.png"),
  GBP: require("./assets/img/flags/united-kingdom.png"),
  JPY: require("./assets/img/flags/japan.png"),
  RUB: require("./assets/img/flags/russia.png"),
  TRY: require("./assets/img/flags/turkey.png"),
  BTC: require("./assets/img/flags/bitcoin.png"),
  ETH: require("./assets/img/flags/ethereum.png"),
  USDT: require("./assets/img/flags/tether.png"),
  CAD: require("./assets/img/flags/canada.png"),
  USD: require("./assets/img/flags/united-states.png"),
  AUD: require("./assets/img/flags/australia.png"),
  SEK: require("./assets/img/flags/SEK.png"),
  KRW: require("./assets/img/flags/KRW.png"),
  IQD: require("./assets/img/flags/iraq.png"),
  INR: require("./assets/img/flags/india.png"),
  AZN: require("./assets/img/flags/azerbaijan.png"),
  NOK: require("./assets/img/flags/NOK.png"),
  NZD: require("./assets/img/flags/NZD.png"),
  SGD: require("./assets/img/flags/singapore.png"),
  DKK: require("./assets/img/flags/DKK.png"),
  PHP: require("./assets/img/flags/philippines.png"),
  HKD: require("./assets/img/flags/hong-kong.png"),
};
export const profileTitles = {};

export const statusColor = {
  Approved: "#13D500",
  Active: "#13D500",
  Inacive: "#D61452",
  Declined: "#D61452",
  Pending: "#F9B233",
  Suspend: "#F9B233",
};
export const status = {};
export const Plugins = {};
export const getStatusColor = (status) => {
  if (status === "Approved" || status === "Active") return "text-primary";
  if (status === "Pending" || status === "Suspend") return "text-warning";
  return "text-danger";
};
export const getStatusName = (status, lang) => {
  if (lang === "fa") {
    if (status === "Active") return "فعال";
    if (status === "Suspend") return "غیر فعال";
    if (status === "Inactive") return "غیر فعال";
    if (status === "Archive") return "آرشیو شده";
  }

  if (lang === "en") {
    if (status === "Active") return "Active";
    if (status === "Suspend") return "Inactive";
    if (status === "Inactive") return "Inactive";
    if (status === "Archive") return "Archive";
  }
};

export const errorGenerator = (label, lang = "en") => {
  return languages[lang][label] + " " + errorsStrings[lang].required;
};

export const appColors = {
  primary: "#03B585",
  secondary: "#444140",
};
